<template>
  <div>
    <div class="text-center mt-0 mb-3" v-if="Caricatoqui == false">
        <b-spinner
            variant="primary"
            style="width: 2.5rem; height: 2.5rem;"
            class="mr-1"
            label="loading"
        />
    </div>

    <div v-else>
      <div v-if="this.testata_nome_pacchetto">
        <h3 class="mb-1">{{ this.testata_nome_pacchetto }}</h3>
        <h6 class="mb-3">
          <span v-if="this.field_split_payment == 'No'">
            <span v-if="this.field_vat_scheme_id == 1">
            Importo totale: {{ prezzoFormattatoStripeQUI(this.testata_prezzo_pacchetto) }} +iva (22%)
            </span>
            <span v-else>
              Importo totale: {{ prezzoFormattatoStripeQUI(this.testata_prezzo_pacchetto) }} (esente iva)
            </span> 
          </span>
          <span v-else>
            Importo totale: {{ prezzoFormattatoStripeQUI(this.testata_prezzo_pacchetto) }} (esente iva)
          </span>
        </h6>
      </div>
          
    </div>

    <form @submit.prevent="handlePayment">
      <!-- Elemento Stripe Payment Element -->
      <div id="payment-element"></div>

      <!-- Errori di pagamento -->
      <p v-if="errorMessage" class="error">{{ errorMessage }}</p>

      <div v-if="isButtonVisible" class="mt-2">
        <b-button 
          variant="outline-primary" 
          class="text-uppercase mb-3" 
          type="submit" 
          :disabled="isLoading || !isFormValid"
        >
          
          <div v-if="isLoading">Pagamento in corso <b-spinner small class="ml-1" /></div>
          <div v-else>Paga</div> 
        </b-button>
      </div>


    </form>

  </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';
import { BButton, BSpinner } from 'bootstrap-vue'


export default {
  components: {
    BButton, 
    BSpinner, 
  },
  props: {
    field_stripe_api_key: {
      type: String,
      required: true,
    },
    field_payment_intent: {
      type: String,
      required: true,
    },
    field_license_id: {
      type: Number,
      //type: String,
      required: true,
    },
    field_customer: {
      type: String,
      required: true,
    },
    field_codice_contratto: {
      type: String,
      required: true,
    },
    field_product_id: {
      type: String,
      required: true,
    },
    field_is_subscription: {
      type: Boolean,
      required: true,
    },
    field_vat_scheme_id: {
      type: Number,
      required: true,
    },
    field_split_payment: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      Caricatoqui: false, 

      stripe: null,
      elements: null,
      paymentElement: null,
      isLoading: false,
      errorMessage: null,
      isSuccess: false,

      testata_nome_pacchetto: '',
      testata_prezzo_pacchetto: '',
      testata_iva_pacchetto: null,
      
      nome_pacchetto: '',
      price_pacchetto_stripe: '', // 'price_XXXX' - serve solo per le subscription

      isButtonVisible: false,  // Inizialmente il bottone non è visibile
      isFormValid: false, // Stato di validità del form

      selectedPaymentMethod: null,

      importo_da_tassare_su_stripe: 0, //default 0 = No, non da tassare

      clientSecretOk: '',
    };
  },
  computed: {
    formattedAmount() {
      return (this.amount / 100).toFixed(2) + ' €';
    },
  },
  async mounted() {
    console.log("Siamo in PAYMENTFORM.VUE - async mounted ---------------------------------")
    console.log(this.field_payment_intent)

    //caricare informazioni di default per il nome del pacchetto che andremo a pagare a prescindere dalle operazioni successive
    this.$http.get('api/customer/stripe/infolicenza/'+this.field_product_id).then(response_pacchetto => { 
      //console.log(response_pacchetto)
      //alert("NOME PACCHETTO -> "+response_pacchetto.data.reply.name)

      this.testata_nome_pacchetto = response_pacchetto.data.reply.name;
      this.testata_prezzo_pacchetto = response_pacchetto.data.reply.default_price.unit_amount;
      this.testata_iva_pacchetto = response_pacchetto.data.reply.tax_code; //se NON indicato su Stripe nella scheda del prodott è di defualt NULL

      this.Caricatoqui = true;
      
    }).catch(e => {
      console.log("Errore api recupero stripe infolicenza ......")
      console.log(e)

      this.Caricatoqui = false;
      alert("Errore nel caricamento delle informazioni del pacchetto ...")
    });

    if(this.field_payment_intent != ''){
      //PAYMENT_INTENT GIA' CREATO - l'ho recuperato dal DB nella tabella Licences

      const dataSalvato = { clientSecret: this.field_payment_intent };
      const { clientSecret } = dataSalvato;

      if (!clientSecret) {
        throw new Error('Il clientSecret non è stato restituito dal server.');
      }

      //estrai nome dal pacchetto in fase di pagamento ...
      const response = this.$http.post('/api/customer/stripe/nome-pacchetto', 
        {
          payment_intent_secret_stripe: this.field_payment_intent,
        }
      );

      const risposta = await response

      /*
      console.log("risposta .............")
      console.log(risposta)
      console.log("dati dall'api .............")
      console.log(risposta.data.reply)
      */

      //visualizza dati
      this.nome_pacchetto = risposta.data.reply.nome_pacchetto

      // 'price_XXXX' di Stripe del pacchetto (serve in caso di subscription)
      this.price_pacchetto_stripe = risposta.data.reply.prezzo_pacchetto

      // Inizializza Stripe e gli elementi
      this.stripe = await loadStripe(this.field_stripe_api_key);
      this.elements = this.stripe.elements({ clientSecret });

      //salvo per i successivi passaggi (pagamento)
      this.clientSecretOk = this.field_payment_intent

    } else {
      //PRIMO ACCESSO AL PAGAMENTO => VA CREATO IL PAYMENT INTENT

      //gestione pagamento con IVA o senza IVA (esente)
      if(this.field_split_payment == 'No'){
        if(this.field_vat_scheme_id == 1){
          //iva al 22% => applica iva all'importo su Stripe
          this.importo_da_tassare_su_stripe = 1
        } else {
          //iva esente => NON applicare iva all'importo su Stripe
          this.importo_da_tassare_su_stripe = 0
        }
      } else{
        //se Spilt payment = si => su Stripe NO iva
        this.importo_da_tassare_su_stripe = 0
      }


      if(this.field_is_subscription === true){
        //Abbonamento
        console.log("Siamo in ABBONAMENTO ---------------")

        const response = this.$http.post('/api/customer/stripe/crea-setup-intent-per-abbonamento', 
          {
            currency: 'eur', // Specifica la valuta
            payment_method_types: ['card', 'sepa_debit'], // Metodi di pagamento supportati 
            customer: this.field_customer, 
            license_id: this.field_license_id, 
            description: 'Contratto n. '+this.field_codice_contratto,
            product_id: this.field_product_id,
          }
        );

        const risposta = await response

        /*
        console.log("risposta .............")
        console.log(risposta)
        console.log("dati dall'api .............")
        console.log(risposta.data.reply)
        */

        const { clientSecret } = risposta.data.reply;

        if (!clientSecret) {
          throw new Error('Il clientSecret non è stato restituito dal server.');
        }

        //visualizza dati
        this.nome_pacchetto = risposta.data.reply.nome_pacchetto

        // 'price_XXXX' di Stripe del pacchetto (serve in caso di subscription)
        this.price_pacchetto_stripe = risposta.data.reply.prezzo_pacchetto

        this.stripe = await loadStripe(this.field_stripe_api_key); // Inserisci qui la tua chiave pubblica
        this.elements = this.stripe.elements({ clientSecret });

        //salvo per i successivi passaggi (pagamento)
        this.clientSecretOk = risposta.data.reply.clientSecret

      } else {
        //Oneshot

        const response = this.$http.post('/api/customer/stripe/dati-iniziali-payment-element', 
          {
            currency: 'eur', // Specifica la valuta
            payment_method_types: ['card', 'sepa_debit'], // Metodi di pagamento supportati 
            customer: this.field_customer, 
            license_id: this.field_license_id, 
            description: 'Contratto n. '+this.field_codice_contratto,
            product_id: this.field_product_id,
            is_subscription: this.field_is_subscription, //lascia questo per compatibilità lato api (backend) - non è più in uso (qui gestiamo solo le oneshot)
            gestione_iva: this.importo_da_tassare_su_stripe,
          }
        );

        const risposta = await response
        
        
        console.log("risposta .............")
        console.log(risposta)
        console.log("dati dall'api .............")
        console.log(risposta.data.reply)
        

        const { clientSecret } = risposta.data.reply;

        if (!clientSecret) {
          throw new Error('Il clientSecret non è stato restituito dal server.');
        }

        //visualizza dati
        this.nome_pacchetto = risposta.data.reply.nome_pacchetto

        // Inizializza Stripe e gli elementi
        this.stripe = await loadStripe(this.field_stripe_api_key);
        this.elements = this.stripe.elements({ clientSecret });

        //salvo per i successivi passaggi (pagamento)
        this.clientSecretOk = risposta.data.reply.clientSecret
      }

    }


    // Configura il Payment Element
    this.paymentElement = this.elements.create('payment', {
      layout: 'tabs', // Mostra i metodi di pagamento in tab
    });

    // Monta il Payment Element
    this.paymentElement.mount('#payment-element');

    // Aggiungi un listener per validare il form
    this.paymentElement.on('change', (event) => {
      this.isFormValid = !event.error && event.complete; // Abilita il bottone solo se completo e senza errori
      this.errorMessage = event.error ? event.error.message : null; // Mostra eventuali errori

      //gestione selezione metodo di pagamento
      if (event.value.type === 'card') {
        //CARTE DI CREDITO
        console.log('Carta di credito');
        this.selectedPaymentMethod = 'card';

      } else if (event.value.type === 'sepa_debit') {
        // SEPA
        console.log('SEPA');
        this.selectedPaymentMethod = 'sepa_debit';

      } else if (event.value.type === 'paypal') {
        // PAYPAL
        console.log('PAYPAL');
        this.selectedPaymentMethod = 'paypal';

      } else {
        //altro per il futuro ...
        console.log('Altro');
      }

    });

    //visualizza bottone "paga"
    console.log("visualizza bottone Paga ---")
    this.isButtonVisible = true;
    
  },
  methods: {
    prezzoFormattatoStripeQUI(prezzo) {
      if (typeof prezzo === 'number') {
          let prezzoStringa = (prezzo / 100).toFixed(2); // Dividiamo per 100 per ottenere il valore con due decimali
          let [prezzo_intero, prezzo_decimali] = prezzoStringa.split('.'); // Separiamo la parte intera e decimale
          let prezzo_elab = prezzo_intero + "," + prezzo_decimali; // Usando la virgola per separare i decimali
          // Aggiungiamo il separatore delle migliaia con il punto
          return prezzo_elab.replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €";
      } else {
          return "---";
      }
    },

    async createPaymentMethod() {
      try {
        const { paymentMethod, error } = await this.stripe.createPaymentMethod({
          type: 'card', // Puoi specificare altri tipi, come 'ideal' o 'bancontact'
          card: this.elements.getElement('card'), // Ottieni l'elemento card dai tuoi elementi
        });

        if (error) {
          console.error('Errore durante la creazione del metodo di pagamento:', error);
          //return null; // O gestisci l'errore come preferisci
        }

        // Associa il metodo di pagamento al cliente
        const { error: attachError } = await this.stripe.attachPaymentMethod(paymentMethod.id, {
          customer: this.field_customer, // l'ID cliente associato -> "cus_XXXXXX"
        });

        if (attachError) {
          console.error('Errore durante l\'associazione del metodo di pagamento:', attachError);
          //return null; // O gestisci l'errore come preferisci
        }

        console.log('ID del metodo di pagamento creato e associato:' + paymentMethod.id); // Restituisci l'ID del metodo di pagamento
      } catch (error) {
        console.error('Errore durante la creazione o l\'associazione del metodo di pagamento:', error);
        //return null; // O gestisci l'errore come preferisci
      }
    },
    
    async handlePayment() {
      this.isLoading = true;
      this.errorMessage = null;

      // Verifica che Stripe e gli elementi siano inizializzati correttamente
      if (!this.stripe || !this.elements || !this.paymentElement) {
        this.errorMessage = 'Stripe Elements non è stato inizializzato correttamente.';
        console.error(this.errorMessage);
        this.isLoading = false;
        return;
      }

      /*
      console.log("this.stripe");
      console.log(this.stripe);
      console.log("this.elements");
      console.log(this.elements);
      console.log("this.paymentElement");
      console.log(this.paymentElement);
      */

      if(this.field_is_subscription === true){
        ///////////////////////////////////////
        //Abbonamento
        console.log("Stiamo provando a pagare un abbonamento - Speriamo bene ...")

        // Conferma il SetupIntent per raccogliere e validare i dati di pagamento
        const { setupIntent, error } = await this.stripe.confirmSetup({
          elements: this.elements,
          confirmParams: {
            return_url: window.location.href, // URL di ritorno dopo il pagamento (opzionale)
          },
          redirect: 'if_required', // usiamo il redirect 'if_required' per rimanere nella stessa pagina
        });

        if (error) {
          console.error(error);
          this.errorMessage = 'Errore durante il pagamento: ' + error.message;
          this.isLoading = false;
          return;
        }

        // Se il SetupIntent è confermato correttamente, otteniamo l'ID del metodo di pagamento
        const paymentMethod = setupIntent.payment_method;

        console.log("Dopo this.stripe.confirmSetup ----------")
        console.log(paymentMethod)
        console.log("price_pacchetto_stripe ----------")
        console.log(this.price_pacchetto_stripe)

        try {
          // Invia al backend il payment_method e il piano per creare la subscription
          const subscriptionResponse = await this.$http.post('/api/customer/stripe/create-subscribe-abbonamento', {
            payment_method: paymentMethod,
            price: this.price_pacchetto_stripe, 

            currency: 'eur', // Specifica la valuta
            customer: this.field_customer, // Sostituisci con l'ID cliente associato -> "cus_XXXXXX"
            license_id: this.field_license_id,
            description: this.nome_pacchetto+' - Contratto n. '+this.field_codice_contratto,
            product_id: this.field_product_id, 
            gestione_iva: this.importo_da_tassare_su_stripe,
          });

          console.log('Subscription ------------------------');
          console.log(subscriptionResponse);
          console.log('Subscription data ------------------------');
          console.log(subscriptionResponse.data);

          // 03/02/2025 - come richiesto da Andrea, anche se il pagamento è in attesa di conferma (ed è normale che sia così per una subcription),
          // -> rimandare comunque il cliente al download del plico (pdf) del contratto
          this.$router.replace('/area-clienti/licenze/downplico/'+this.field_license_id)
            .then(() => {
              this.$swal({
                  icon: 'success',
                  title: 'Il pagamento è in fase di conferma, nel frattempo puoi scaricare il plico del contratto',
                  confirmButtonText: 'chiudi',
                  customClass: {
                  confirmButton: 'btn btn-success',
                  },
              })
            })

        } catch (err) {
          console.error(err);
          this.error =
            err.response?.data?.error ||
            'Errore durante la creazione della subscription.';
        }

      
      } else {
        ///////////////////////////////////////
        //OneShot

        try {
          const { error, paymentIntent } = await this.stripe.confirmPayment({
            elements: this.elements,
            confirmParams: {
              return_url: window.location.href, // URL di ritorno dopo il pagamento (opzionale)
            },
            redirect: "if_required", // usiamo il redirect 'if_required' per rimanere nella stessa pagina
          });

          // Gestione degli errori
          if (error) {
            this.errorMessage = `Errore durante la conferma del pagamento: ${error.message}`;
            console.error(this.errorMessage);
            this.isLoading = false;
            return;
          }

          /*
          console.log("Dopo this.stripe.confirmPayment")
          console.log("paymentIntent: ")
          console.log(paymentIntent)
          console.log("customerId: ")
          console.log(this.field_customer)
          */

          if (paymentIntent && paymentIntent.status === "succeeded") {
            console.log("Il pagamento è stato completato con successo!!");

            //risposta positiva
            this.$router.replace('/area-clienti/licenze/show/'+this.field_license_id)
              .then(() => {
                this.$swal({
                    icon: 'success',
                    title: 'Il pagamento è stato completato con successo',
                    confirmButtonText: 'chiudi',
                    customClass: {
                    confirmButton: 'btn btn-success',
                    },
                })
              })
            
          } else {
            if(paymentIntent.status === "processing") {
              //in caso di pagamento con "addebito sepa", gli stati di risposta di pagamento possono essere "in differita da qualche minuto a qualche giorno"
              if(this.selectedPaymentMethod == "sepa_debit"){
                // 03/02/2025 - come richiesto da Andrea, anche se il pagamento è in attesa di conferma, rimandare il cliente al download del plico (pdf) del contratto

                this.$router.replace('/area-clienti/licenze/downplico/'+this.field_license_id)
                  .then(() => {
                    this.$swal({
                        icon: 'success',
                        title: 'Il pagamento è in fase di conferma, nel frattempo puoi scaricare il plico del contratto',
                        confirmButtonText: 'chiudi',
                        customClass: {
                        confirmButton: 'btn btn-success',
                        },
                    })
                  })

              }
            } else {
              this.errorMessage = "Il pagamento NON è stato completato con successo! La inviatimo a riprovare.";
            }
          }


          /*
          // Conferma il pagamento utilizzando Stripe
          const { error } = await this.stripe.confirmPayment({
            elements: this.elements, 
            confirmParams: {
              return_url: window.location.href, // URL di ritorno dopo il pagamento (opzionale)
            },
            redirect: "if_required",
          });

          // Gestione degli errori
          if (error) {
            this.errorMessage = `Errore durante la conferma del pagamento: ${error.message}`;
            console.error(this.errorMessage);
            this.isLoading = false;
            return;
          }

          // Invio del payment_intent_id al backend
          const paymentIntentId = this.clientSecretOk.split('_secret_')[0];

          const response = await this.$http.post('/api/customer/stripe/salva-pagamento', {
            payment_intent_id: paymentIntentId, // ID del PaymentIntent
            payment_method: 'payment_element', // Specifica che il metodo è gestito da Payment Element
          });

          const datiRisposta = response.data.reply;

          if (datiRisposta.success) {
            console.log('Pagamento completato con successo:', datiRisposta);
            this.isSuccess = true;
          } else {
            this.errorMessage = `Errore nel pagamento: ${datiRisposta.message}`;
            console.error(this.errorMessage);
          }
          */
          



          /*
          // Conferma il pagamento utilizzando Stripe
          const { error, paymentIntent } = await this.stripe.confirmPayment({
            elements: this.elements,
            confirmParams: {
              return_url: window.location.href, // URL di ritorno dopo il pagamento (opzionale)
            },
            redirect: "if_required",
          });

          // Gestione degli errori
          if (error) {
            this.errorMessage = `Errore durante la conferma del pagamento: ${error.message}`;
            console.error(this.errorMessage);
            this.isLoading = false;
            return;
          }

          console.log("Dopo this.stripe.confirmPayment")
          console.log("paymentIntent: ")
          console.log(paymentIntent)
          console.log("customerId: ")
          console.log(this.field_customer)

          const lunghezza = 10; // Lunghezza della stringa
          const caratteri = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
          let nuovaStringa = '';
          for (let i = 0; i < lunghezza; i++) {
            const indice = Math.floor(Math.random() * caratteri.length);
            nuovaStringa += caratteri[indice];
          }



          // Verifica che il pagamento sia stato completato
          if (paymentIntent && paymentIntent.status === "succeeded_STOP_STOP_STOP") {
            // Chiama il backend per creare la fattura
            try {

              const response = this.$http.post('/api/customer/stripe/create-invoice?rand='+nuovaStringa, 
                {
                  paymentIntentId: paymentIntent.id,
                  id_cliente: this.field_customer, // Sostituisci con l'ID cliente associato -> "cus_XXXXXX"
                }
              );

              const result = await response
        
              console.log("risposta create-invoice .............")
              console.log(result)
              console.log("dati dall'api create-invoice .............")
              console.log(result.data.reply)


              if (result.data.reply.ok) {
                console.log("Fattura creata con successo:", result.data.reply.invoiceId);
              } else {
                console.error("Errore durante la creazione della fattura:", result.data.reply.error);
              }
            } catch (err) {
              console.error("Errore di rete:", err);
            }
          } else {
            console.error("Il pagamento non è stato completato con successo. _STOP_STOP_STOP");
          }

          this.isLoading = false;
          */





          /*
          // Conferma il pagamento e crea la fattura
          const { error, paymentIntent } = await this.stripe.confirmPayment({
            elements: this.elements,
            confirmParams: {
              return_url: window.location.href,
            },
            redirect: "if_required",
          });


          if (error) {
            this.errorMessage = `Errore durante la conferma del pagamento: ${error.message}`;
            console.error(this.errorMessage);
            this.isLoading = false;
            return;

          } else {
            console.log("Speriamo bene ... siamo inzio creazione fattura ...")

            // Invio del payment_intent_id al backend

            console.log("pi vecchio -> " + this.clientSecretOk)
            console.log("pi ultimo (dopo this.stripe.confirmPayment) -> " + paymentIntent.id)

            //const paymentIntentId = this.clientSecretOk;

            const response = await this.$http.post('/api/customer/stripe/salva-fattura', {
              payment_intent_id: paymentIntent.id, // ID del PaymentIntent
            });

            const datiRisposta = response.data.reply;

            if (datiRisposta.success) {
              console.log('Pagamento completato con successo:', datiRisposta);
              this.isSuccess = true;
            } else {
              this.errorMessage = `Errore nel pagamento: ${datiRisposta.error}`;
              console.error(this.errorMessage);
            }

            this.isLoading = false;
          }
          */


          

          
        } catch (err) {
          this.errorMessage = `Errore inatteso durante il processo di pagamento: ${err.message}`;
          console.error(err);
        } finally {
          this.isLoading = false;
        }

      }

      
    }

    
  },
};
</script>

<style>
.error {
  color: red;
  padding-top: 20px;
}
</style>
